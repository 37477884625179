//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'QuestWinAnimation',
  props: ['questWinData'],
  methods: {
    close: function close() {
      this.$emit('close');
    }
  }
};