//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'MagicBoxesInfoModal',
  methods: {
    closeMagicBoxesInfoModal: function closeMagicBoxesInfoModal() {
      this.$store.dispatch('modals/setMagicBoxesInfoModal', {
        isOpen: false
      });
    }
  }
};